import useRecenterGraphKey from '../../HUD/hooks/useRecenterGraphKey';
import useViewportDimensions from '../../HUD/hooks/useViewportDimensions';
import { ForceGraphContext } from '../state/GraphContextProvider';
import {
	RECENTER_GRAPH,
	SET_FG_METHODS,
	SET_POPUP_ELEMENT,
	ZOOM_TO_NODE,
} from '../state/actions';
import { UIGraphNode, UILinkObject } from '../types/graphTypes';
import { forceCollide } from 'd3-force';
import { useEffect, useRef, useContext, useCallback } from 'react';
import { ForceGraphMethods } from 'react-force-graph-2d';

const useGraphHandlers = () => {
	const recenterKey = useRecenterGraphKey();

	const [graphState, graphDispatch] = useContext(ForceGraphContext);

	// interaction handlers
	const timerRef = useRef<ReturnType<typeof setTimeout> | null>(null);

	const selectNode = (node: UIGraphNode) => {
		graphDispatch({ type: ZOOM_TO_NODE, payload: node });

		if (timerRef.current) {
			clearTimeout(timerRef.current);
		}

		// Wait for zoom animation to finish before opening popover
		timerRef.current = setTimeout(() => {
			timerRef.current = null;
			graphDispatch({ type: SET_POPUP_ELEMENT, payload: node });
		}, 500);
	};

	const selectLink = (link: UILinkObject) =>
		graphDispatch({ type: SET_POPUP_ELEMENT, payload: link });

	const { viewportHeight: height, viewportWidth: width } =
		useViewportDimensions();

	const fgmRef = useRef<null | ForceGraphMethods>(null);

	const setFgm = useCallback((fgm: ForceGraphMethods) => {
		fgmRef.current = fgm;
	}, []);

	useEffect(() => {
		if (fgmRef.current !== null) {
			graphDispatch({ type: SET_FG_METHODS, payload: fgmRef.current });
		}
	}, [fgmRef, graphDispatch]);

	const lifecycleData = useRef<{
		seenNodes: Set<number>;
		seenNodesTimeout: null | ReturnType<typeof setTimeout>;
		recenterKeyTimeout: null | ReturnType<typeof setTimeout>;
	}>({
		seenNodes: new Set(),
		recenterKeyTimeout: null,
		seenNodesTimeout: null,
	});

	// Listen for the 'center' event from Redux store; i.e.
	// whenever the key changes, run the centering function.
	useEffect(() => {
		if (
			graphState.forceGraphMethods &&
			graphState.graphData.nodes.length > 0
		) {
			lifecycleData.current.recenterKeyTimeout = setTimeout(
				() => graphDispatch({ type: RECENTER_GRAPH }),
				150
			);
		}

		return () => {
			const {
				current: { recenterKeyTimeout },
			} = lifecycleData;

			if (recenterKeyTimeout) {
				clearTimeout(recenterKeyTimeout);
			}
		};
	}, [
		graphState.forceGraphMethods,
		recenterKey,
		graphDispatch,
		graphState.graphData,
	]);

	//  Re-center the graph whenever fresh data loads.
	// Check previous ids to determine if effect should trigger; we DON'T
	// want a re-center every time the cache is invalidated, e.g. by an action
	// taken from a popover--should only fire on genuinely new data when user
	// navigates to a different view.
	useEffect(() => {
		const cleanup = () => {
			const {
				current: { seenNodesTimeout },
			} = lifecycleData;

			if (seenNodesTimeout) {
				clearTimeout(seenNodesTimeout);
			}
		};

		const nextIds = graphState.graphData.nodes.map((n: any) => n._id);

		if (lifecycleData.current.seenNodes.size === 0) {
			//    don't need to run centering function the first time data loads
			lifecycleData.current.seenNodes = new Set(nextIds);

			return cleanup;
		}

		if (nextIds.some((id) => !lifecycleData.current.seenNodes.has(id))) {
			lifecycleData.current.seenNodes.clear();
			lifecycleData.current.seenNodes = new Set(nextIds);
			lifecycleData.current.seenNodesTimeout = setTimeout(
				() => graphDispatch({ type: RECENTER_GRAPH }),
				250
			);
		}

		return cleanup;
	}, [graphState.graphData, graphDispatch]);

	//  Adjust forces for a more legible node layout
	useEffect(() => {
		if (graphState.forceGraphMethods) {
			(graphState.forceGraphMethods.d3Force('charge') as any).strength(
				// if there is a ghost node, balance out charges so it doesn't
				// invisibily repel other node
				(node: any) => (node.isGhost ? 30 : -30)
			);
			(graphState.forceGraphMethods.d3Force('link') as any).distance(50);
			graphState.forceGraphMethods.d3Force('collide', forceCollide(20));
		}
	}, [graphState.forceGraphMethods]);

	return {
		selectLink,
		selectNode,
		width,
		height,
		setFgm,
		graphState,
		graphDispatch,
	};
};

export default useGraphHandlers;
