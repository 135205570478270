import { truncateLinkURL } from '../helpers';
import { ContextLadder, ContextParams } from '../types/navigationContextTypes';
import { useParams } from 'react-router-dom';

const useOntologyParams = (targetCtxLevel: ContextLadder[number]) => {
	const params = useParams<ContextParams>();

	const linkBuilder = truncateLinkURL(params, targetCtxLevel);

	return { ...params, linkBuilder };
};

export default useOntologyParams;
