import { pipe } from '../../common/utils/functionUtils';
import { Empty, EMPTY } from '../../common/utils/typeUtils';
import { JWT } from '../authentication/types/tokenTypes';
import { isExpired } from '../tokens';
import { isEmpty, isNonEmptyString } from 'common/utils/typeGuards';

export const setToken = (token: JWT) => {
	localStorage.setItem('token', token);
	return token;
};

export const ensureNonNullable = (token: unknown) =>
	isNonEmptyString(token) ? token : EMPTY;

export const clearToken = () => localStorage.removeItem('token');

export const clearLocalStorage = () => localStorage.clear();

const ensureUnexpired = (token: string | Empty) => {
	if (isEmpty(token)) {
		return token;
	}

	if (isExpired(token)) {
		clearToken();
		return EMPTY;
	}

	return token;
};

/**
 * Try to retrieve an unexpired JSON web token from localStorage.  Otherwise,
 * return Empty.
 */
export const getToken = () =>
	pipe(localStorage.getItem('token'), ensureNonNullable, ensureUnexpired);
