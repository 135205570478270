import useResizeObserver from '@react-hook/resize-observer';
import { useCallback, useLayoutEffect, useState } from 'react';

const useElementSize = () => {
	const [target, setTarget] = useState<HTMLElement | null>(null);

	const [size, setSize] = useState<{ width: number; height: number }>({
		width: 0,
		height: 0,
	});

	useLayoutEffect(() => {
		if (target) {
			setSize(target.getBoundingClientRect());
		}
	}, [target]);

	const cb = useCallback((e: ResizeObserverEntry) => {
		setSize(e.contentRect);
	}, []);

	useResizeObserver(target, cb);

	return [size, setTarget] as const;
};

export default useElementSize;
