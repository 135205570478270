import { BaseAttribute } from '../../ontology/types/attributeTypes';
import { emptyStringToNull } from '../common/helpers';
import {
	AggregateAttrFormValues,
	AggregateAttrPayload,
	AggregationType,
} from './aggregateAttributeTypes';

export const aggregateAttrFormDefaults = (): AggregateAttrFormValues => ({
	singular: '',
	plural: '',
	definition: '',
	aggregationType: 'none',
	aggregatingId: 0,
});

export const aggregateAttrFormToPayload = (
	formValues: AggregateAttrFormValues
): AggregateAttrPayload => {
	return {
		...formValues,
		definition: emptyStringToNull(formValues.definition),
		// Ok to cast this, since form validation will fail if value is 'none'
		aggregationType: formValues.aggregationType as AggregationType,
	};
};

export const getAvailableAggregationTypes = (
	attr: BaseAttribute
): readonly AggregationType[] => {
	const normalAggs = ['count', 'list', 'mode'] as const;

	const quantityAggs = [
		'count',
		'sum',
		'max',
		'median',
		'average',
		'percentile',
		'stddev',
		'min',
		'range',
	] as const;

	switch (attr.type) {
		case 'category':
			return normalAggs;

		case 'identity':
			return normalAggs;

		case 'relation':
			return normalAggs;

		case 'quantity':
			return quantityAggs;

		default:
			return [];
	}
};
