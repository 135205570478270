import BreadcrumbBase from './BreadcrumbBase';
import { extractQueryErrMessage } from 'features/api/helpers';
import useBreadcrumbQuery from 'features/navigation/hooks/useBreadcrumbQuery';
import { ContextLevels } from 'features/navigation/types/navigationContextTypes';
import { FunctionComponent } from 'react';

interface BreadcrumbProps {
	kind: ContextLevels;
	isTip: boolean;
}

const Breadcrumb: FunctionComponent<BreadcrumbProps> = ({ kind, isTip }) => {
	const { links, activeName, isError, error, isLoading, isUninitialized } =
		useBreadcrumbQuery(kind);

	const status =
		isLoading || isUninitialized
			? 'loading'
			: isError
			? 'error'
			: 'success';

	const errMessage = error ? extractQueryErrMessage(error) : null;

	return (
		<BreadcrumbBase
			isTip={isTip}
			activeName={activeName}
			kind={kind}
			status={status}
			errorMessage={errMessage}
			links={links}
		/>
	);
};

export default Breadcrumb;
