import { pipe } from '../../../common/utils/functionUtils';
import {
	boolStringToBool,
	emptyStringToNull,
	resetDataValue,
	resetIdentifyingFields,
} from '../common/helpers';
import {
	AppendAttrFormValues,
	AppendAttrPayload,
} from './appendAttributeTypes';

export const appendAttrFormDefaults = (): AppendAttrFormValues => ({
	operationType: 'append',
	singular: '',
	plural: '',
	isDerivation: 'false',
	delimiter: 'none',
	otherId: 0,
	otherValue: '',
	definition: '',
	usingStaticReference: 'true',
});

export const appendAttrFormToPayload = (
	formValues: AppendAttrFormValues
): AppendAttrPayload => {
	const baseValues = {
		...formValues,
		isDerivation: boolStringToBool(formValues.isDerivation),
		definition: emptyStringToNull(formValues.definition),
		usingStaticReference: boolStringToBool(formValues.usingStaticReference),
		delimiter:
			formValues.delimiter === 'none' ? null : formValues.delimiter,
	};

	return pipe(
		baseValues,
		resetIdentifyingFields(baseValues.isDerivation),
		resetDataValue(baseValues.usingStaticReference)
	);
};
