import { centerOnNode, selectPadding } from '../helpers';
import { TwoDGraphState, RECENTER_GRAPH, PAUSE_ANIMATION } from './actions';
import { MutatingGraphAction, ZOOM_TO_NODE } from './actions';
import { Reducer } from 'react';
import { ForceGraphMethods } from 'react-force-graph-2d';

// handles actions that mutate graph files or manipulate camera. It is necessary
// to do this in-place to keep ThreeJS and animation state in-sync, as well as for
// performance purposes.
const mutatingGraphContextReducer: Reducer<
	TwoDGraphState,
	MutatingGraphAction
> = (s, a) => {
	// TODO: should have a real null check here
	const { forceGraphMethods: fgm } = s as {
		forceGraphMethods: ForceGraphMethods;
	};

	switch (a.type) {
		case ZOOM_TO_NODE:
			centerOnNode(fgm, a.payload);
			return s;

		case RECENTER_GRAPH:
			const padding = selectPadding(s.graphData.nodes.length);
			//    ForceGraphMethods doesn't know about extra node properties
			//    @ts-ignore
			fgm.zoomToFit(1000, padding, (node) => !node.isGhost);
			return s;

		case PAUSE_ANIMATION:
			fgm.pauseAnimation();
			return s;
	}
};

export default mutatingGraphContextReducer;
