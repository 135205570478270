import { selectViewportDimensions } from '../state/HUDSlice';
import { useSelector } from 'react-redux';
import { shallowEqual } from 'react-redux';

/**
 * Shallow equality check here is critical.  Without it, components that use this hook
 * will re-render on scroll, which would be a performance catastrophe.
 */
const useViewportDimensions = () =>
	useSelector(selectViewportDimensions, shallowEqual);

export default useViewportDimensions;
