import React, {
	FunctionComponent,
	Children,
	cloneElement,
	isValidElement,
	ReactNode,
} from 'react';

const hiddenForScreenReader = {
	position: 'absolute',
	left: '-10000px',
	top: 'auto',
	width: '1px',
	height: '1px',
	overflow: 'hidden',
};

interface VisuallyHiddenProps {
	children?: ReactNode;
}

/**
 * Visually hide elements that provide accessbility information to screen readers,
 * e.g. labels
 */
export const VisuallyHidden: FunctionComponent<VisuallyHiddenProps> = ({
	children,
	...props
}) => {
	if (isValidElement(children)) {
		return (
			<>
				{Children.map(children, (child) => {
					return cloneElement(child, {
						...props,
						style: {
							...child.props.style,
							...hiddenForScreenReader,
						},
					});
				})}
			</>
		);
	}

	return null;
};

export default VisuallyHidden;
