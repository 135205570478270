import { flow } from '../../../common/utils/functionUtils';
import { RestrictEntityOperator } from '../common/commonTypes';
import {
	emptyStringToNull,
	zeroToNull,
	nullStringToNull,
} from '../common/helpers';
import {
	CreateConditionalFormValues,
	CreateConditionalPayload,
	ConditionFormValues,
	ConditionPayload,
} from './createConditionalTypes';

/**
 * If user has selected e.g. a static value and then toggled to using
 * an attribute id, the static value will still be present on the submitted
 * form values.  Use the value of the toggle fields to zero out any properties
 * that are unused based on the users last selections before submission.
 */
const resetCondition = (
	condition: ConditionFormValues
): ConditionFormValues => {
	const { usingStaticReference, usingStaticResult } = condition;

	if (usingStaticReference === 'true') {
		Object.assign(condition, { referenceId: 0 });
	} else {
		Object.assign(condition, { referenceValue: '' });
	}

	if (usingStaticResult === 'true') {
		Object.assign(condition, { resultId: 0 });
	} else {
		Object.assign(condition, { resultValue: '' });
	}

	return condition;
};
const resetPayload = (
	formValues: CreateConditionalFormValues
): CreateConditionalFormValues => {
	const { usingStaticElseValue } = formValues;

	if (usingStaticElseValue === 'true') {
		Object.assign(formValues, { elseId: 0 });
	} else {
		Object.assign(formValues, { elseValue: '' });
	}

	return formValues;
};

export const conditionFormDefaults = (): ConditionFormValues => ({
	subjectId: 0,
	usingStaticReference: 'false',
	referenceId: 0,
	referenceValue: '',
	comparisonType: 'none',
	usingStaticResult: 'false',
	resultId: 0,
	resultValue: '',
});

export const createConditionalFormDefaults =
	(): CreateConditionalFormValues => ({
		singular: '',
		plural: '',
		definition: '',
		conditions: [conditionFormDefaults()],
		usingStaticElseValue: 'false',
		elseId: 0,
		elseValue: '',
	});

export const conditionFormToPayload = (
	formValues: ConditionFormValues
): ConditionPayload => ({
	subjectId: formValues.subjectId,
	resultValue: emptyStringToNull(formValues.referenceValue),
	resultId: zeroToNull(formValues.resultId),
	comparisonType: formValues.comparisonType as RestrictEntityOperator,
	referenceValue: nullStringToNull(formValues.referenceValue),
	referenceId: zeroToNull(formValues.referenceId),
});

export const createConditionalFormToPayload = (
	formValues: CreateConditionalFormValues
): CreateConditionalPayload => {
	const conditions = formValues.conditions.map(
		flow(resetCondition, conditionFormToPayload)
	);

	const basePayload = resetPayload(formValues);

	return {
		...basePayload,
		definition: emptyStringToNull(basePayload.definition),
		conditions,
		elseId: zeroToNull(basePayload.elseId),
		elseValue: emptyStringToNull(basePayload.elseValue),
	};
};
