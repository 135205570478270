import { useAppDispatch } from '../../../app/hooks';
import { logout } from '../state/userSlice';
import { useNavigate } from 'react-router';

const useLogout = () => {
	const appDispatch = useAppDispatch();
	const navigate = useNavigate();

	return () => {
		appDispatch(logout());
		navigate('/');
	};
};

export default useLogout;
