import { RootState } from '../../app/store';
import environment from '../../common/environment';
import { refreshToken } from '../authentication/state/tokenSlice';
import { logout } from '../authentication/state/userSlice';
import { lessThan5MinsRemaining } from '../tokens';
import { isQueryHTTPRejection } from './helpers';
import {
	BaseQueryFn,
	FetchArgs,
	FetchBaseQueryError,
} from '@reduxjs/toolkit/dist/query';
import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { isEmpty } from 'common/utils/typeGuards';

const { API_ROOT } = environment;

const selfAuthorizingBaseQuery = fetchBaseQuery({
	baseUrl: API_ROOT,
	prepareHeaders: (headers, { getState }) => {
		const token = (getState() as RootState).token;

		const tokenPresent = !isEmpty(token);

		if (tokenPresent) {
			headers.set('authorization', `Bearer ${token}`);
		}

		return headers;
	},
});

export const baseQueryWithLogout: BaseQueryFn<
	string | FetchArgs,
	unknown,
	FetchBaseQueryError
> = async (args, api, extraOptions) => {
	const { dispatch, getState } = api;

	const token = (getState() as RootState).token;

	const tokenPresent = !isEmpty(token);

	if (tokenPresent) {
		const tokenIsStale = lessThan5MinsRemaining(token);

		//   IMPORTANT: check explicitly for 'true' here, since AppError would also be
		// a truthy result, but should NOT attempt to refresh.
		if (tokenIsStale === true) {
			dispatch(refreshToken());
		}
	}

	const result = await selfAuthorizingBaseQuery(args, api, extraOptions);

	if (isQueryHTTPRejection(result.error) && result.error.status === 401) {
		api.dispatch(logout());
	}

	return result;
};
