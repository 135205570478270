import { BaseEntity } from '../../ontology/types/entityTypes';
import { emptyStringToNull } from '../common/helpers';
import {
	MeltEntityPayload,
	MeltEntityFormValues,
	MeltEntityParams,
} from './meltEntityTypes';

export const meltEntityFormDefaults = (
	entity: BaseEntity
): MeltEntityFormValues => {
	return {
		singular: entity.singular,
		plural: entity.plural,
		definition: entity.definition ?? '',
	};
};

export const meltEntityFormToPayload = (
	formValues: MeltEntityFormValues
): MeltEntityPayload => {
	return {
		...formValues,
		definition: emptyStringToNull(formValues.definition),
	};
};

export const meltEntityPayloadToParams = (
	payload: MeltEntityPayload,
	entityId: number
): MeltEntityParams => ({
	body: payload,
	entityId,
});
