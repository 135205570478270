import { selectTokenExpirys } from 'features/authentication/state/userSlice';
import { useSelector } from 'react-redux';

const useOAuthTokenIsValid = (provider: 'linkedin') => {
	const expirys = useSelector(selectTokenExpirys);

	if (expirys === null) {
		return false;
	}

	const relevantExpiry = expirys[provider];

	// token expiration fields on user object are in seconds (not MS) since Unix epoch.
	// Give ourselves an extra minute on the expiration for the server xaction to complete.
	// Server makes its own expiry check, and will ignore the received auth code if it is not
	// needed, but will fail if its own expiry check returns true and no auth code has been sent.
	// Better to send it and not need it then.

	return relevantExpiry === null
		? false
		: Date.now() / 1000 <= relevantExpiry - 60;
};

export default useOAuthTokenIsValid;
