import { RootState } from '../../../app/store';
import { DispatchableError, ErrorState } from '../types/errorTypes';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Cast to union type ErrorType to prevent createSlice from narrowing to
// EmptyErrorState based on the value provided initialState.
// We want the type available in Redux state to be a union.
export const initialState = null as ErrorState;

// TODO: in the real world, we should differentiate between displaying the
// error-derived UI and the presence or absence of an error
const errSlice = createSlice({
	name: 'error',
	initialState,
	reducers: {
		applicationErr: (state, action: PayloadAction<DispatchableError>) => {
			return action.payload;
		},

		clearErr: () => {
			return null;
		},
	},
});

export const { applicationErr, clearErr } = errSlice.actions;

// Be sure to get 'null' if there's no active error, not 'undefined'
export const selectErrMessage = (s: RootState) => s.err?.message ?? null;

export default errSlice.reducer;
