import { DomainGraphNode } from '../../ontology/types/domainTypes';
import { UIGraphNode } from './graphTypes';

/**
 * Minimum contract of node files once it has been processed by 2D graph
 * component. Note that a domain graph node REPRESENTS an
 * entity. This is the shape of the node files that
 * react-force-graph-2D will pass to click/hover event handlers.
 */
export interface UIDomainGraphNode extends DomainGraphNode {
	x: number;
	y: number;
	kind: 'node';
	color: string;
	// we add this property for "artificial" nodes that prevent graph from
	// every having less than 2 nodes--having a single node causes a canvas scaling
	// bug wherein the single node zooms out until it disappears.
	isGhost: boolean;
}

export const isUIDomainGraphNode = (
	node: UIGraphNode
): node is UIDomainGraphNode => node._object === 'Entity';
