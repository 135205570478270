import { AppError } from 'features/errorHandling/types/errorTypes';

/**
 * NODE_ENV is guaranteed by Create React App, so reasonably safe to assume it will be set.
 * https://create-react-app.dev/docs/adding-custom-environment-variables/
 */
let hasWarnedOnce = false;
export const warnOnEnvUseInDevelopment = (
	variableName: string,
	nodeEnv: string
) => {
	if (nodeEnv === 'development' && !hasWarnedOnce) {
		hasWarnedOnce = true;
		console.warn(
			`You're using environment variables, including ${variableName}--please make sure that the value of these variables are NOT A SECRET, as they will be sent to the client`
		);
	}
};

const variableIsMissing = (s: string | undefined) =>
	typeof s === 'undefined' || (typeof s === 'string' && s.length === 0);

export const missingVarMessage = (missingVars: string[]) =>
	'The following environment variables are required in this project, ' +
	'but were undefined or empty strings: ' +
	`${missingVars.join(', ').trim()}`;

export const getVerifiedEnvironment = <T>(
	required: readonly string[],
	env: Record<string, string | undefined>
) => {
	const missing: string[] = [];

	const result = required.reduce((acc, varName) => {
		const v = env[varName] || env[`REACT_APP_${varName}`];

		if (variableIsMissing(v)) {
			missing.push(varName);
			return acc;
		}

		//   TODO: seems like these types could be made to work...
		const trimmedName = varName.replace(/^REACT_APP_/, '') as keyof T;

		acc[trimmedName] = v as any;

		return acc;
	}, {} as T);

	if (missing.length > 0) {
		throw new AppError(missingVarMessage(missing));
	}

	return result;
};

/**
 * VERY IMPORTANT--READ THIS!!
 *
 * Unlike server-side code, environment variables in bundled React apps are a build time configuration convenience--
 * they are exposed in the bundle that is served to the client, and NOTHING IN THE BUNDLE IS
 * SECRET.  Please DO NOT put anything here or in a .env file that needs to be a secret, e.g. API keys, passwords,
 * business-sensitive info.
 */
const requiredInEnv = [
	'API_ROOT',
	'USE_API_SERVICE_WORKER',
	'NODE_ENV',
	'FB_APP_ID',
	'LINKED_IN_ID',
	'LINKED_IN_REDIRECT_URI',
] as const;

type ExpectedEnvironment = { [Key in typeof requiredInEnv[number]]: string };

const environment = getVerifiedEnvironment<ExpectedEnvironment>(
	requiredInEnv,
	process.env
);

export default environment;
