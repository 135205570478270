import { toUIGraph, UILinkToVCLink, UINodeToVCNode } from '../helpers';
import { isUINodeObject } from '../types/graphTypes';
import {
	CLOSE_CREATION_FORM,
	GraphAction,
	TwoDGraphState,
	OPEN_CREATION_FORM,
	SET_FG_METHODS,
	SET_GRAPH_DATA,
	SET_POPUP_ELEMENT,
	SET_LOADING_STATE,
} from './actions';
import mutatingGraphContextReducer from './mutatingReducer';
import { Reducer } from 'react';
import { ForceGraphMethods } from 'react-force-graph-2d';

export const initialState: TwoDGraphState = {
	previousPopupElementIdentity: null,
	creationFormOpen: false,
	graphData: { nodes: [], links: [] },
	popupElement: null,
	forceGraphMethods: null,
	isLoadingData: false,
};

const graphContextReducer: Reducer<TwoDGraphState, GraphAction> = (s, a) => {
	switch (a.type) {
		case OPEN_CREATION_FORM:
			return { ...s, creationFormOpen: true };

		case CLOSE_CREATION_FORM:
			return { ...s, creationFormOpen: false };

		case SET_FG_METHODS:
			return { ...s, forceGraphMethods: a.payload };

		case SET_GRAPH_DATA:
			return { ...s, graphData: toUIGraph(a.payload) };

		case SET_LOADING_STATE:
			return { ...s, isLoadingData: a.payload };

		case SET_POPUP_ELEMENT:
			// no re-render if same element is clicked multiple times in a row
			if (a.payload === s.previousPopupElementIdentity) return s;

			// setting to null closes the popup
			if (a.payload === null)
				return {
					...s,
					previousPopupElementIdentity: a.payload,
					popupElement: a.payload,
				};

			// TODO: should probably have a real null check for forceGraphMethods here.
			const { forceGraphMethods } = s as {
				forceGraphMethods: ForceGraphMethods;
			};

			const mapped = isUINodeObject(a.payload)
				? UINodeToVCNode(forceGraphMethods, a.payload)
				: UILinkToVCLink(forceGraphMethods, a.payload);

			return {
				...s,
				previousPopupElementIdentity: a.payload,
				popupElement: mapped,
			};

		default:
			mutatingGraphContextReducer(s, a);
			return s;
	}
};

export default graphContextReducer;
