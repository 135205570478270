import { pipe } from '../../../common/utils/functionUtils';
import {
	boolStringToBool,
	resetDataValue,
	resetIdentifyingFields,
	stripFormOnlyProps,
} from '../common/helpers';
import { MergeAttrFormValues, MergeAttrPayload } from './mergeAttributeTypes';

export const mergeAttrFormDefaults = (): MergeAttrFormValues => ({
	operationType: 'merge',
	singular: '',
	plural: '',
	isDerivation: 'false',
	// use 'min' requirement to force a selection to be made if input is active
	otherId: 0,
	otherValue: '',
	definition: '',
	usingStaticReference: 'true',
});

export const mergeAttrFormToPayload = (
	formValues: MergeAttrFormValues
): MergeAttrPayload => {
	const baseValues = {
		...formValues,
		isDerivation: boolStringToBool(formValues.isDerivation),
		usingStaticReference: boolStringToBool(formValues.usingStaticReference),
	};

	return pipe(
		baseValues,
		stripFormOnlyProps,
		resetIdentifyingFields(baseValues.isDerivation),
		resetDataValue(baseValues.usingStaticReference)
	);
};
