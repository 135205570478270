import { useAppDispatch } from '../../../app/hooks';
import { pipe } from '../../../common/utils/functionUtils';
import { applicationErr } from '../state/errorSlice';
import { AppError, toDispatchableErr } from '../types/errorTypes';
import { useCallback } from 'react';

const useDispatchableErr = () => {
	const appDispatch = useAppDispatch();

	return useCallback(
		(e: AppError) =>
			pipe(e, toDispatchableErr, applicationErr, appDispatch),
		[appDispatch]
	);
};

export default useDispatchableErr;
