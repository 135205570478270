import {
	OAuthProvider,
	LinkedInLoginMessage,
	OAUTH_ACCESS_CODE_REQUEST_MESSAGE,
} from './types';
import { FunctionComponent, useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

const isUserCancellation = (msg: string | null) =>
	msg === 'user_cancelled_login' || msg === 'user_cancelled_authorize';

const paramsToParsedParams = (
	rawParams: URLSearchParams,
	strat: OAuthProvider
): LinkedInLoginMessage | null => {
	const base = {
		provider: strat,
		source: OAUTH_ACCESS_CODE_REQUEST_MESSAGE as typeof OAUTH_ACCESS_CODE_REQUEST_MESSAGE,
	};

	const expectedFields = ['error', 'error_description', 'code', 'state'];

	const parsed = expectedFields.reduce(
		(acc, next) => ({ ...acc, [next]: rawParams.get(next) }),
		{} as any
	);

	//  if for some reason required info is not in search params at all,
	// return null so message-passing logic knows not to execute
	if (parsed.error === undefined && parsed.code === undefined) {
		return null;
	}

	if (parsed.error) {
		const msg = isUserCancellation(parsed.error)
			? parsed.error
			: typeof parsed.error_description === 'string'
			? parsed.error_description
			: 'Login failed with unknown error.';

		return {
			...base,
			state: parsed.state,
			payload: {
				error: msg,
				status: 'error',
				code: null,
			},
		};
	}

	if (parsed.code) {
		return {
			...base,
			state: parsed.state,
			payload: {
				error: null,
				status: 'success',
				code: parsed.code,
			},
		};
	}

	return {
		...base,
		state: parsed.state,
		payload: {
			error: 'No error was offered, but no auth code was available in provider response',
			status: 'error',
			code: null,
		},
	};
};

const Callback: FunctionComponent = () => {
	const { oauthProvider } = useParams<{ oauthProvider: OAuthProvider }>();
	const searchParams = useSearchParams()[0];

	const parsedParams = paramsToParsedParams(
		searchParams,
		// probably ok to cast here since this component only gets mounted in callback routes
		oauthProvider as OAuthProvider
	);

	const postMessage = (data: LinkedInLoginMessage) => {
		window.opener.postMessage(data, window.location.origin);
	};

	useEffect(() => {
		if (parsedParams) {
			if (isUserCancellation(parsedParams.payload.error)) {
				// NB: the hook that opened popup window will detect this close and clean itself up
				// if this conditional runs.
				window.close();
				return;
			}

			postMessage(parsedParams);
		}
	}, [parsedParams]);

	return <div>Your request should continue in a moment...</div>;
};

export default Callback;
