import styled from 'styled-components';

const Fade = styled.div<{ $duration: number }>`
	z-index: ${(p) => p.theme.zIndex.controlSurface};
	&.fade-transition-enter {
		opacity: 0;
	}

	&.fade-transition-enter-active {
		opacity: 1;
		transition: opacity ${(p) => p.$duration}ms;
	}

	&.fade-transition-exit {
		opacity: 1;
	}
	&.fade-transition-exit-active {
		opacity: 0;
		transition: opacity ${(p) => p.$duration}ms;
	}
`;

export default Fade;
