import { pipe } from '../../../common/utils/functionUtils';
import {
	boolStringToBool,
	resetDataValue,
	resetIdentifyingFields,
	stripFormOnlyProps,
} from '../common/helpers';
import {
	DivideAttrFormValues,
	DivideAttrPayload,
} from './divideAttributeTypes';

export const divideAttrFormDefaults = (): DivideAttrFormValues => ({
	operationType: 'divide',
	singular: '',
	plural: '',
	isDerivation: 'false',
	// use 'min' requirement to force a selection to be made if input is active
	otherId: 0,
	otherValue: '',
	definition: '',
	replaceMissing: 'false',
	usingStaticReference: 'true',
});

export const divideAttrFormToPayload = (
	formValues: DivideAttrFormValues
): DivideAttrPayload => {
	const baseValues = {
		...formValues,
		replaceMissing: boolStringToBool(formValues.replaceMissing),
		isDerivation: boolStringToBool(formValues.isDerivation),
		usingStaticReference: boolStringToBool(formValues.usingStaticReference),
	};

	return pipe(
		baseValues,
		stripFormOnlyProps,
		resetIdentifyingFields(baseValues.isDerivation),
		resetDataValue(baseValues.usingStaticReference)
	);
};
