import { FunctionComponent } from 'react';
import { Link, LinkProps, useLocation } from 'react-router-dom';
import styled from 'styled-components';

/**
 * React router Link component.  Removes native text decoration and text color.
 */
const StyledLink = styled(Link)`
	text-decoration: none;
	color: inherit;
	cursor: pointer;
`;

interface RouterLinkProps extends LinkProps {
	preserveSearchParams?: boolean;
}

const RouterLink: FunctionComponent<RouterLinkProps> = (props) => {
	const { preserveSearchParams = true, to, ...linkBaseProps } = props;

	const { search } = useLocation();

	const toAppend = preserveSearchParams ? search : '';

	return <StyledLink to={to + toAppend} {...linkBaseProps} />;
};

export default RouterLink;
