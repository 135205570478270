export const timeResolutions = [
	'yearId',
	'quarterId',
	'monthId',
	'weekId',
	'dayId',
	'hourId',
	'minuteId',
	'secondId',
	'millisecondId',
	'microsecondId',
	'nanosecondId',
	'dateId',
	'dateTimeId',
	'timestampId',
	'timeId',
] as const;

export interface CreateEventPayload {
	singular: string;
	plural: string;
	definition: string | null;
	yearId: number | null;
	quarterId: number | null;
	monthId: number | null;
	weekId: number | null;
	dayId: number | null;
	hourId: number | null;
	minuteId: number | null;
	secondId: number | null;
	millisecondId: number | null;
	microsecondId: number | null;
	nanosecondId: number | null;
	dateId: number | null;
	dateTimeId: number | null;
	timestampId: number | null;
	timeId: number | null;
	timezone: string | null;
}

export interface CreateEventFormValues {
	singular: string;
	plural: string;
	definition: string;
	yearId: number;
	quarterId: number;
	monthId: number;
	weekId: number;
	dayId: number;
	hourId: number;
	minuteId: number;
	secondId: number;
	millisecondId: number;
	microsecondId: number;
	nanosecondId: number;
	dateId: number;
	dateTimeId: number;
	timestampId: number;
	timeId: number;
	timezone: string;
}

export interface CreateEventActionParams {
	entityId: number;
	body: CreateEventPayload;
}

export interface CreateEventActionResponse {}
