import { clearToken, setToken } from '.';
import { RootState } from '../../app/store';
import {
	tokenReceived,
	refreshToken,
} from '../authentication/state/tokenSlice';
import { logout, userRegistered } from '../authentication/state/userSlice';
import { Middleware } from 'redux';

const localStorageMiddleware: Middleware<{}, RootState> =
	() => (next) => (action) => {
		if (tokenReceived.match(action)) {
			setToken(action.payload);
		}

		if (refreshToken.fulfilled.match(action)) {
			setToken(action.payload);
		}

		if (logout.match(action)) {
			clearToken();
		}

		if (userRegistered.match(action)) {
			setToken(action.payload.token);
		}

		return next(action);
	};

export default localStorageMiddleware;
