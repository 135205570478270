import { RootState } from '../../../app/store';
import { warnInDev } from '../../../common/utils/reactUtils';
import { EMPTY } from '../../../common/utils/typeUtils';
import URLBuilder from '../../api/urlBuilder';
import { TokenState } from '../types/tokenTypes';
import { JWT } from '../types/tokenTypes';
import { logout, userRegistered } from './userSlice';
import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { isEmpty } from 'common/utils/typeGuards';

/**
 * Responsible for silently refreshing the user's auth token whenever
 * they make requests with less than a pre-specified amount of time
 * left before the token expires.
 */
export const refreshToken = createAsyncThunk<string, undefined>(
	'token/refreshToken',
	(arg, { dispatch, getState, rejectWithValue }) => {
		const token = (getState() as RootState).token;

		const fail = () => rejectWithValue('Refresh failed');
		// future-proofing in case this ever gets called in a flow that doesn't
		// verify token is populated before using it. Still have to return
		// a promise.
		if (isEmpty(token)) {
			warnInDev(
				`"Refresh token" thunk was dispatched while there was no auth token in application state. This is irrational--please check usage of "refreshToken".`,
				'warn'
			);
			dispatch(logout());
			return fail();
		}

		return fetch(URLBuilder.refreshToken.buildURL(), {
			headers: {
				Authorization: `Bearer ${token}`,
			},
			method: 'POST',
		})
			.then((res) => {
				if (res.ok) {
					return res;
				}

				return Promise.reject({ message: 'Refresh failed' });
			})
			.then((res) => res.json())
			.then(({ token }) => token)
			.catch((e) => {
				warnInDev(
					`Application initially had a valid token, but refresh failed with message:\n${e.message}`,
					'warn'
				);
				dispatch(logout());
				fail();
			});
	}
);

const initialState = EMPTY as TokenState;

const tokenSlice = createSlice({
	name: 'token',
	initialState,
	reducers: {
		tokenReceived: (state, action: PayloadAction<JWT>) => {
			return action.payload;
		},
	},

	extraReducers: (builder) => {
		builder
			.addCase(logout, () => {
				return EMPTY;
			})
			.addCase(userRegistered, (s, a) => a.payload.token)
			.addCase(refreshToken.fulfilled, (s, a) => {
				return a.payload;
			});
	},
});

export const { tokenReceived } = tokenSlice.actions;

export const selectAuthToken = (s: RootState) => s.token;

export default tokenSlice.reducer;
