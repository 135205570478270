import { RootState } from '../../app/store';
import { warnInDev } from '../../common/utils/reactUtils';
import { applicationErr } from './state/errorSlice';
import { Middleware } from 'redux';

const errorMiddleware: Middleware<{}, RootState> = () => (next) => (action) => {
	if (applicationErr.match(action)) {
		warnInDev(action.payload.message, 'error');
	}

	return next(action);
};

export default errorMiddleware;
