import { warnInDev } from '../../common/utils/reactUtils';
import { keyExists } from '../../common/utils/typeUtils';
import { AppError } from '../errorHandling/types/errorTypes';
import { isAppError, isNonEmptyString } from 'common/utils/typeGuards';
import jwtDecode from 'jwt-decode';

const getExpiration = (token: string): AppError | number => {
	// jwtDecode throws if token is malformed (even though types don't express this),
	// so we need to deal with that case.
	try {
		const { exp } = jwtDecode<{ exp?: number }>(token);

		if (!exp) {
			return new AppError(
				'Token retrieved from localStorage was well-formed, but did not encode an expiration property'
			);
		}

		return exp;
	} catch (e) {
		let message: string = 'unknown error type';

		// overwrite message if there's something usable in the thrown error
		if (keyExists(e, 'message') && isNonEmptyString(e.message)) {
			message = e.message;
		}

		return new AppError(
			'Token retrieved from localStorage was malformed; decoding produced the following err:\n' +
				message
		);
	}
};

export const hasAtLeastSecondsRemaining =
	(remaining: number) => (token: string) => {
		const exp = getExpiration(token);

		if (isAppError(exp)) {
			warnInDev(
				`An exception occurred while checking token staleness:\n${token}\nmessage:\n${exp.message}\nThere may be further information in subsequent logs.`,
				'error'
			);
			return exp;
		}

		return exp - Date.now() / 1000 >= remaining;
	};

export const isExpired = (token: string) => {
	const hasAtLeast10 = hasAtLeastSecondsRemaining(10)(token);

	if (isAppError(hasAtLeast10)) {
		// if error, return true, since we should consider the token expired/invalid
		// in this case
		return true;
	}

	// Leave a little bit of cushion to avoid situation where token is nearly expired
	// but is accepted, then immediately triggers a token refresh and expires while refresh request
	// is in-flight, leading to refresh failure and a very confusing problem.
	return !hasAtLeast10;
};

// Magic number: 300 = 60 * 5 (5 mins)
export const lessThan5MinsRemaining = (token: string) => {
	const fiveMinsLeft = hasAtLeastSecondsRemaining(300)(token);

	if (isAppError(fiveMinsLeft)) {
		warnInDev(
			'The above error was generated while performing a staleness check to potentially trigger a token refresh. Refresh aborting.',
			'warn'
		);

		return fiveMinsLeft;
	}

	return !fiveMinsLeft;
};
