import {
	LinkedInLoginMessage,
	OAUTH_ACCESS_CODE_REQUEST_MESSAGE,
	PKCE_MISMATCH,
} from './types';
import environment from 'common/environment';
import { initQueryStateTracker, QueryStateTracker } from 'features/api/helpers';
import { nanoid } from 'nanoid';
import { useCallback, useEffect, useRef, useState } from 'react';

const appendState = (url: string, state: string) => `${url}&state=${state}`;

const getPopupPositionProperties = (
	dims: { width: number; height: number } = { width: 500, height: 500 }
) => {
	const { width, height } = dims;
	const left = window.innerWidth / 2 - width / 2;
	const top = window.innerHeight / 2 - height / 2;
	return `left=${left},top=${top},width=${width},height=${height}`;
};

const initCodeStateTracker = () => ({ ...initQueryStateTracker(), code: null });

// HEAVILY inspired by https://github.com/nvh95/react-linkedin-login-oauth2 -- much gratitude to the author.
const useLinkedInCodeRequest = () => {
	const [status, setStatus] = useState<
		QueryStateTracker & { code: string | null }
	>({ ...initQueryStateTracker(), code: null });

	const reset = useCallback(() => setStatus(initCodeStateTracker()), []);

	const pkce = useRef<string | null>(null);
	const popupRef = useRef<Window | null>(null);
	const windowClosePollingRef = useRef<ReturnType<typeof setInterval> | null>(
		null
	);

	const closePopup = () => {
		if (windowClosePollingRef.current !== null) {
			clearInterval(windowClosePollingRef.current);
			windowClosePollingRef.current = null;
		}
		popupRef.current?.close();
		popupRef.current = null;
	};

	const clearPKCE = () => (pkce.current = null);

	const pollForClose = useCallback(() => {
		const cb = () => {
			if (popupRef.current?.closed) {
				closePopup();

				// PKCE state should never outlive a given auth popup
				clearPKCE();

				// closing popup before finishing auth code exchange isn't an error state--just reset.
				reset();
			}
		};

		windowClosePollingRef.current = setInterval(cb);
	}, [reset]);

	const handleMessage = useCallback(
		(event: MessageEvent<LinkedInLoginMessage>) => {
			// if the message isn't from Callback component, ignore it.
			if (event.data.source !== OAUTH_ACCESS_CODE_REQUEST_MESSAGE) {
				return;
			}

			closePopup();

			if (event.origin !== window.location.origin) {
				// This case should never be hit, but for completeness' sake...
				console.warn(
					`OAuth popup event handler received a message from an unexpected origin:\n${event.origin}`
				);
				return;
			}

			if (pkce.current === null || event.data.state !== pkce.current) {
				return setStatus((p) => ({
					...p,
					error: {
						status: 401,
						data: {
							message: PKCE_MISMATCH,
						},
					},
					isError: true,
				}));
			}

			if (event.data.payload.error) {
				return setStatus((p) => ({
					...p,
					isError: true,
					error: {
						status: 500,
						data: { message: event.data.payload.error },
					},
				}));
			}

			if (event.data.payload.code) {
				return setStatus({
					...initCodeStateTracker(),
					isSuccess: true,
					code: event.data.payload.code,
				});
			}

			// once a message that's associated with a given PKCE state has been received, clear it --
			// very important from a security standpoint never to re-use it.
			clearPKCE();

			return null;
		},
		[]
	);

	useEffect(() => {
		window.addEventListener('message', handleMessage, false);

		const cleanup = () =>
			window.removeEventListener('message', handleMessage, false);

		return cleanup;
	}, [handleMessage]);

	const login = useCallback(() => {
		closePopup();

		reset();

		pkce.current = nanoid(20);

		window.open(
			appendState(
				`https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${environment.LINKED_IN_ID}&scope=w_member_social%20r_liteprofile&redirect_uri=${environment.LINKED_IN_REDIRECT_URI}`,
				pkce.current
			),
			'_blank',
			getPopupPositionProperties()
		);

		pollForClose();
	}, [pollForClose, reset]);

	return { ...status, login };
};

export default useLinkedInCodeRequest;
