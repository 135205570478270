import { AttributeSubtype } from '../../ontology/types/attributeTypes';
import { emptyStringToNull } from '../common/helpers';
import {
	CreateAttrFormValues,
	CreateAttrPayload,
} from './createAttributeTypes';

export const createAttrFormDefaults = (): CreateAttrFormValues => ({
	singular: '',
	plural: '',
	definition: '',
	type: 'quantity',
	otherType: '',
	defaultValue: '',
});

export const createAttrFormToPayload = (
	formValues: CreateAttrFormValues,
	parentEntityId: number
): CreateAttrPayload => {
	return {
		...formValues,
		defaultValue: emptyStringToNull(formValues.defaultValue),
		otherType: emptyStringToNull(
			formValues.defaultValue
		) as AttributeSubtype | null,
		entityId: parentEntityId,
	};
};
