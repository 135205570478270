import { LoadAttrFormValues, LoadAttrPayload } from './loadAttributeTypes';

export const loadAttrFormDefaults = (): LoadAttrFormValues => ({
	attributeId: 0,
});

export const loadAttrFormToPayload = (
	formValues: LoadAttrFormValues
): LoadAttrPayload => {
	return formValues;
};
