import { pipe } from '../../../common/utils/functionUtils';
import {
	boolStringToBool,
	emptyStringToNull,
	resetDataValue,
	resetIdentifyingFields,
	stripFormOnlyProps,
} from '../common/helpers';
import { AddAttrFormValues, AddAttrPayload } from './addAttributeTypes';

export const addAttrFormDefaults = (): AddAttrFormValues => ({
	operationType: 'add',
	singular: '',
	plural: '',
	isDerivation: 'false',
	// use 'min' requirement to force a selection to be made if input is active
	otherId: 0,
	otherValue: '',
	definition: '',
	replaceMissing: 'false',
	usingStaticReference: 'true',
});

export const addAttrFormToPayload = (
	formValues: AddAttrFormValues
): AddAttrPayload => {
	const baseValues = {
		...formValues,
		replaceMissing: boolStringToBool(formValues.replaceMissing),
		isDerivation: boolStringToBool(formValues.isDerivation),
		definition: emptyStringToNull(formValues.definition),
		usingStaticReference: boolStringToBool(formValues.usingStaticReference),
	};

	return pipe(
		baseValues,
		stripFormOnlyProps,
		resetIdentifyingFields(baseValues.isDerivation),
		resetDataValue(baseValues.usingStaticReference)
	);
};
