// Borrowed with gratitude from https://github.com/NadavCohen/react-fork-ref/blob/master/src/index.ts
import environment from '../environment';
import useViewportDimensions from 'features/HUD/hooks/useViewportDimensions';
import { Ref, MutableRefObject, useCallback } from 'react';
import { useLayoutEffect, useState } from 'react';

export const useForkRef = <T>(...refs: Ref<T>[]) => {
	return useCallback(forkRef(...refs), []);
};

export function forkRef<T>(...refs: Ref<T>[]) {
	return function (node: T | null) {
		for (const ref of refs) {
			if (typeof ref === 'function') ref(node);
			else if (ref) (ref as MutableRefObject<T | null>).current = node;
		}
	};
}

type LogLevel = 'log' | 'warn' | 'error';

const selectLogFN = (level: LogLevel) => {
	const { log, warn, error } = console;

	if (level === 'log') return log;

	if (level === 'warn') return warn;

	return error;
};

export const warnInDev = (msg: string, logLevel: LogLevel, ...rest: any[]) => {
	const { NODE_ENV } = environment;

	const logFn = selectLogFN(logLevel);

	if (NODE_ENV === 'development') {
		logFn(msg, ...rest);
	}
};

export const useClientRect = <T extends HTMLElement>() => {
	const dims = useViewportDimensions();

	const [node, setNode] = useState<T | null>(null);

	const [rect, setRect] = useState({ width: 0, height: 0 });

	// whever screen resizes, read the node again.  Very janky,
	// but ResizeObserver not working correctly for drawing charts atm.
	useLayoutEffect(() => {
		if (node) {
			setRect(node.getBoundingClientRect());
		}
	}, [dims, node]);

	return [rect, setNode] as const;
};
