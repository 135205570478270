import { emptyStringToNull, zeroToNull } from '../common/helpers';
import {
	CreateEventFormValues,
	CreateEventPayload,
	timeResolutions,
} from './createEventTypes';
import { UseFormSetError } from 'react-hook-form';

export const createEventFormDefaults = (): CreateEventFormValues => ({
	singular: '',
	plural: '',
	definition: '',
	yearId: 0,
	quarterId: 0,
	monthId: 0,
	weekId: 0,
	dayId: 0,
	hourId: 0,
	minuteId: 0,
	secondId: 0,
	millisecondId: 0,
	microsecondId: 0,
	nanosecondId: 0,
	dateId: 0,
	dateTimeId: 0,
	timestampId: 0,
	timeId: 0,
	timezone: '',
});

export const createEventFormToPayload = (
	formValues: CreateEventFormValues
): CreateEventPayload => {
	//  convert 0 values to null
	// convert tz empty string to null
	const nullifiedTimeResValues = timeResolutions.reduce((acc, resolution) => {
		acc[resolution] = zeroToNull(formValues[resolution]);
		return acc;
	}, {} as { [K in typeof timeResolutions[number]]: number | null });

	return {
		...formValues,
		...nullifiedTimeResValues,
		timezone: emptyStringToNull(formValues.timezone),
	};
};

// same Id can't be used twice
// at least one resolution must be populated
export const validateTimeResolutionSubmission = (
	setErr: UseFormSetError<CreateEventFormValues>,
	submitted: CreateEventFormValues
) => {
	let hasFlaggedError = false;

	const usedIds = new Set<number>();

	timeResolutions.forEach((tr) => {
		const submittedVal = submitted[tr];

		if (submittedVal === 0) {
			return;
		}

		if (usedIds.has(submittedVal)) {
			setErr(tr, {
				type: 'manual',
				message: `The same attribute (${submittedVal}) may not be used for multiple time resolutions`,
			});

			hasFlaggedError = true;

			return;
		}

		usedIds.add(submittedVal);
		return;
	});

	if (hasFlaggedError) {
		return false;
	}

	if (usedIds.size === 0) {
		setErr(timeResolutions[0], {
			type: 'required',
			message: 'At least one time resolution attribute must be selected',
		});

		return false;
	}

	return true;
};
