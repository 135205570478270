import { ContextLadder, ContextParams } from '../types/navigationContextTypes';
import { isAppError } from 'common/utils/typeGuards';
import useDispatchableErr from 'features/errorHandling/hooks/useDispatchableErr';
import { truncateLinkURL } from 'features/navigation/helpers';
import { useParams } from 'react-router-dom';

// the lion's share of the logic here is covered by unit tests for "truncateLinkURL"
const useLinkTruncater = () => {
	const dispatchErr = useDispatchableErr();
	const params = useParams<ContextParams>();

	return (targetCtxLevel: ContextLadder[number], value: string) => {
		const t = truncateLinkURL(params, targetCtxLevel);

		if (isAppError(t)) {
			dispatchErr(t);

			// if this is error, we don't have anywhere that exists to go, so 404.
			return '/404';
		}

		return t(value);
	};
};

export default useLinkTruncater;
