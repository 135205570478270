import AttributeMeta from './AttributeMeta';
import {
	CoordMeta,
	LongLatBounds,
	InvalidCoordMeta,
	Coord,
} from './dataPreparationTypes';

class LatLongMeta extends AttributeMeta {
	coords: CoordMeta[] = [];
	//  base value is whole world view
	//  [wb sb] [eb nb]
	boundingCoords: LongLatBounds = [
		[-180, -90],
		[180, 90],
	];
	invalidCoords: InvalidCoordMeta[] = [];
	private northBound: number | null = null;
	private southBound: number | null = null;
	private westBound: number | null = null;
	private eastBound: number | null = null;

	ingest(value: unknown, idx: number) {
		super.ingest(value, idx);

		if (value === undefined || value === null) {
			// parent class handles this case--do nothing
			return;
		}

		if (!this.isValidCoord(value)) {
			this.invalidCoords.push({ value, ownerIdx: idx });
			return;
		}
		this.coords.push({ ownerIdx: idx, coord: value });
		this.updateBounds(value);
	}

	finalize(): void {
		this.setBounds();
	}

	private setBounds() {
		const bounds = [
			this.westBound,
			this.southBound,
			this.eastBound,
			this.northBound,
		];

		if (this.allBoundsSet(bounds)) {
			const [wb, sb, eb, nb] = bounds;

			if (this.representsSingleCoord(wb, sb, eb, nb)) {
				this.boundingCoords = this.getBoundsForSingleCoord([nb, wb]);
				return;
			}

			this.boundingCoords = [
				[wb, sb],
				[eb, nb],
			];
		}
	}

	// if only 1 valid coordinate has been ingested, set bounding coordinates that
	// amount to a square of 1 degree per-side, with the single coordinate at
	// its center.
	private getBoundsForSingleCoord(coord: Coord) {
		const [lat, long] = coord;

		return [
			[long - 0.5, lat - 0.5],
			[long + 0.5, lat + 0.5],
		] as [[number, number], [number, number]];
	}

	private representsSingleCoord(
		wb: number,
		sb: number,
		eb: number,
		nb: number
	) {
		return wb === eb && nb === sb;
	}

	private allBoundsSet(
		bounds: Array<number | null>
	): bounds is [number, number, number, number] {
		return [
			this.northBound,
			this.southBound,
			this.westBound,
			this.eastBound,
		].every((v) => typeof v === 'number');
	}

	private isValidCoord(value: unknown): value is Coord {
		return (
			Array.isArray(value) &&
			value.length === 2 &&
			value.every((el) => typeof el === 'number')
		);
	}

	private updateBounds(coord: Coord) {
		const [lat, long] = coord;

		if (this.northBound === null || lat > this.northBound) {
			this.northBound = lat;
		}

		if (this.southBound === null || lat < this.southBound) {
			this.southBound = lat;
		}

		if (this.westBound === null || long < this.westBound) {
			this.westBound = long;
		}

		if (this.eastBound === null || long > this.eastBound) {
			this.eastBound = long;
		}
	}
}

export default LatLongMeta;
